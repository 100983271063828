module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-scroll-reveal/gatsby-browser.js'),
      options: {"plugins":[],"threshold":0.1,"once":true,"selector":"[data-sal]","animateClassName":"sal-animate","disabledClassName":"sal-disabled","rootMargin":"0% 50%","enterEventName":"sal:in","exitEventName":"sal:out"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"FulcrumGT","short_name":"FulcrumGT","start_url":"/","background_color":"#e5f3fb","theme_color":"#e5f3fb","display":"standalone","icon":"./src/assets/favicon.svg","icons":[{"src":"src/assets/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"src/assets/android-chrome-512x512.png","sizes":"512x512","type":"image/png"},{"src":"src/assets/favicon-16x16.png","sizes":"16x16","type":"image/png"},{"src":"src/assets/favicon-32x32.png","sizes":"32x32","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"172dd88ef8181e01fd69b4e04e5c695d"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"G-8GPNMY3YDR","cookieName":"gatsby-gdpr-google-analytics","anonymize":true,"allowAdFeatures":false,"cookieFlags":"SameSite=Lax; Secure"},"googleTagManager":{"trackingId":"GTM-NP6NW3N","cookieName":"gatsby-gdpr-google-tagmanager","dataLayerName":"dataLayer"},"linkedin":{"trackingId":"4221652","cookieName":"gatsby-gdpr-linked-in"},"hubspot":{"trackingId":"5325021","cookieName":"gatsby-gdpr-hubspot"},"vimeo":{"trackingId":"841886180","cookieName":"gatsby-gdpr-vimeo"},"environments":["production","development"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
